import qs from 'qs'

export class DataSource {
  appOptions
  authHeader
  ctx
  requests
  requestWrapper

  static httpClient (...params) {
    return fetch(...params)
  }

  static qs = qs

  constructor (ctx, appOptions, authHeader, requestWrapper) {
    this.ctx = ctx
    this.appOptions = appOptions
    this.authHeader = authHeader
    this.requests = {}
    this.requestWrapper = requestWrapper || ((options, promise) => promise())
  }

  _parse (response, accept) {
    if (response.status !== 200 && response.status !== 201) {
      return response.json().then(data => {
        const error = {
          ...data,
          message: data.detail || response.statusText
        }

        return Promise.reject(error)
      })
    }

    if (accept?.includes('json')) {
      return response.json()
    }

    const acceptPattern = /^application\/([a-zA-Z]+(\+)?|)pdf$/

    if (acceptPattern.test(accept) || accept?.includes('text/html')) {
      return response.text().then(text => {
        const value = response.headers
          .get('Content-Type')
          .split(';')
          .map(val => val.split('='))
          .find(val => (val[0] || '').includes('size'))

        return {
          text,
          pageSize: value
            ? value[1]
            : value
        }
      })
    }

    return response.text().then(value => {
      if ((value.startsWith('{') && value.endsWith('}')) || (value.startsWith('[') && value.endsWith(']'))) {
        return JSON.parse(value)
      }

      return value
    })
  }

  getRaw (url, get = {}, accept = 'application/json', headers = {}, noEndPoint = false, parser = (res) => res) {
    const controller = new AbortController()
    const signal = controller.signal

    const requestOptions = {
      method: 'GET',
      headers: {
        'Accept': accept,
        'Accept-Language': this.appOptions && this.appOptions.locale
          ? this.appOptions.locale.locale
          : '',
        ...this.authHeader(),
        ...headers
      },
      signal: signal
    }

    let urlPlus = 'get:' + url + (Object.keys(get).length > 0 ? '?' + DataSource.qs.stringify(get) : '')
    if (this.requests[urlPlus]) {
      this.requests[urlPlus].abort({ code: 499, message: 'The user aborted a request.' })
    }

    this.requests[urlPlus] = controller

    const endpoint = url.match(/\/apps\//) || noEndPoint
      ? ''
      : '/api'

    let fullURL = this.appOptions.defaultServer + endpoint + url + (Object.keys(get).length > 0 ? '?' + DataSource.qs.stringify(get) : '')
    if (url.startsWith('https')) {
      fullURL = url + (Object.keys(get).length > 0 ? '?' + DataSource.qs.stringify(get) : '')
    }

    return this.requestWrapper(
      { ...requestOptions, endpoint, url, get, fullURL },
      () => DataSource.httpClient(fullURL, requestOptions).then(x => {
        if (x.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            window.location.reload()
          }, '3000')
          return parser(x, accept)
        } else {
          return parser(x, accept)
        }

      })
    )
  }

  _blobParser (response) {
    if (response.status !== 200 && response.status !== 201) {
      return response.json().then(data => {
        const error = {
          ...data,
          message: data.detail || response.statusText
        }

        return Promise.reject(error)
      })
    }

    return response.blob()
  }

  download (url, get, accept) {
    return this.getRaw(url, get, accept, undefined, undefined, this._blobParser)
      .then(blob => {
        console.debug(blob)
        const url = this.ctx.URL.createObjectURL(blob)
        const a = this.ctx.document.createElement('a')
        a.href = url
        a.download = 'document.xls'
        a.click()
        return blob
      })
  }

  downloadRaw (template, accept) {
    const controller = new AbortController()
    const signal = controller.signal
    const url = template.file

    const requestOptions = {
      method: 'GET',
      headers: {
        'Accept': accept,
        // ...this.authHeader()
      },
      signal: signal
    }

    let urlPlus = 'get:' + url
    if (this.requests[urlPlus]) {
      this.requests[urlPlus].abort({ code: 499, message: 'The user aborted a request.' })
    }

    this.requests[urlPlus] = controller

    let parts = template.file.split('/')
    let templateName = parts[parts.length - 1]

    return this.requestWrapper(
      { ...requestOptions, url },
      () => DataSource.httpClient(url, requestOptions)
        .then(x => x.blob())
        .then(blob => {
          const url = this.ctx.URL.createObjectURL(blob)
          const a = this.ctx.document.createElement('a')
          a.href = url
          a.download = templateName
          a.click()
          return blob
        })
    )
  }

  get (url, get = {}, accept = 'application/json', headers = {}) {
    return this.getRaw(url, get, accept, headers, undefined, this._parse)
  }

  getHref (url) {
    return this.get(url.split('api')[1])
  }

  getLink (url) {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...this.authHeader()
      }
    }

    return DataSource.httpClient(url, requestOptions)
  }

  post (url, data, accept = 'application/json', contentType = 'application/json', api = '/api') {
    const controller = new AbortController()
    const signal = controller.signal
    const method = 'POST'

    const requestOptions = {
      method,
      headers: {
        ...this.authHeader(),
        'Content-Type': contentType,
        'Accept': accept
      },
      body: JSON.stringify(data),
      signal: signal
    }

    if (this.requests['post:' + url]) {
      this.requests['post:' + url].abort({ code: 499, message: 'The user aborted a request.' })
    }

    this.requests['post:' + url] = controller
    const fullURL = this.appOptions.defaultServer + api + url

    return this.requestWrapper(
      { ...requestOptions, url, data, fullURL },
      () => DataSource.httpClient(fullURL, requestOptions).then(x => this._parse(x, accept))
    )
  }

  upload (url, file, api = '/api') {
    const controller = new AbortController()
    const signal = controller.signal
    const method = 'POST'

    const formData = new FormData()
    formData.append(file.name, new Blob([file]), file.name)

    const requestOptions = {
      method,
      headers: {
        ...this.authHeader(),
        'Accept': 'application/json',
      },
      body: formData,
      signal: signal
    }

    if (this.requests['post:' + url]) {
      this.requests['post:' + url].abort({ code: 499, message: 'The user aborted a request.' })
    }

    this.requests['post:' + url] = controller

    return DataSource.httpClient(this.appOptions.defaultServer + api + url, requestOptions).then(x => this._parse(x))
  }

  uploadPatch (url, file, api = '/api', field) {
    const controller = new AbortController()
    const signal = controller.signal
    const method = 'PATCH'

    const formData = new FormData()
    formData.append(field, new Blob([file]), field)

    const requestOptions = {
      method,
      headers: {
        ...this.authHeader()
      },
      body: formData,
      signal: signal
    }

    if (this.requests['post:' + url]) {
      this.requests['post:' + url].abort({ code: 499, message: 'The user aborted a request.' })
    }

    this.requests['post:' + url] = controller

    return DataSource.httpClient(this.appOptions.defaultServer + api + url, requestOptions).then(x => this._parse(x))
  }

  patch (url, data, accept = 'application/json', api = '/api', headers = {}, query = {}) {
    const method = 'PATCH'

    const requestOptions = {
      method,
      headers: {
        ...this.authHeader(),
        'Content-Type': 'application/json',
        'Accept': accept,
        ...headers
      },
      body: JSON.stringify(data)
    }

    const fullURL = this.appOptions.defaultServer + api + url + (Object.keys(query).length > 0 ? '?' + DataSource.qs.stringify(query) : '')

    return this.requestWrapper(
      { ...requestOptions, url, fullURL, api, data, query },
      () => DataSource.httpClient(fullURL, requestOptions).then(x => this._parse(x, accept))
    )
  }

  put (url, data, accept = 'application/json', query = {}) {
    const method = 'PUT'
    const requestOptions = {
      method,
      headers: {
        ...this.authHeader(),
        'Content-Type': 'application/json',
        'Accept': accept
      },
      body: JSON.stringify(data)
    }

    const fullURL = this.appOptions.defaultServer + '/api' + url + (Object.keys(query).length > 0 ? '?' + DataSource.qs.stringify(query) : '')

    return this.requestWrapper(
      { ...requestOptions, url, fullURL, data, query },
      () => DataSource.httpClient(fullURL, requestOptions).then(x => this._parse(x, accept))
    )
  }

  saveRequest (url, data, id, accept, api, headers, query) {
    if (url[url.length - 1] !== '/' && id) {
      url = url + '/'
    }

    if (url[url.length - 1] === '/' && !id) {
      url = url.slice(0, url.length - 1)
    }

    if (headers && headers['X-Entity-Updated'] && typeof headers['X-Entity-Updated'] === 'object') {
      headers['X-Entity-Updated'] = headers['X-Entity-Updated'].date
    }

    return id
      ? this.patch(url + id, data, accept, api, headers, query)
      : this.post(url, data, accept)
  }

  bulk (url, data) {
    return this.post(`${url}/bulk`, data).then(data => {
      const errors = data.filter(x => x.isError)

      if (errors.length > 0) {
        return Promise.reject(new Error(errors.map(x => x.error).join(', ')))
      }

      return data.map(x => ({ [x.command]: (x.params || {}).item }))
    })
  }
}
