import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    sources: [],
    source: null,
    cleanSource: null,
    totalSourcesNumber: 0,
    sourcesPage: 1,
    sourcesTotalPages: 1,
    sourcesLoading: false,
    sourcesByIntegration: {}
  },
  getters: {
    sources: state => state.sources,
    source: state => state.source,
    cleanSource: state => state.cleanSource,
    totalSourcesNumber: state => state.totalSourcesNumber,
    sourcesPage: state => state.sourcesPage,
    sourcesTotalPages: state => state.sourcesTotalPages,
    sourcesLoading: state => state.sourcesLoading,
    sourcesByIntegration: state => state.sourcesByIntegration,
    hasSourcesForIntegration: state => integrationId => {
      const sources = state.sourcesByIntegration || {};

      return sources[integrationId] && sources[integrationId].length > 0;
    },
    getSourcesForIntegration: state => integrationId => {
      const sources = state.sourcesByIntegration || {};

      return sources[integrationId] && sources[integrationId].length > 0;
    }
  },
  mutations: {
    setSource (state, payload) {
      state.source = payload
      state.cleanSource = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanSource (state, payload) {
      state.cleanSource = payload
    },
    setSourcesData (state, { items, page, totalPages, totalItems }) {
      state.sources = items
      state.totalSourcesNumber = totalItems
      state.sourcesPage = page
      state.sourcesTotalPages = totalPages

      this.commit('setSourcesByIntegration', state);
    },
    setSources (state, payload) {
      state.sources = payload
    },
    setTotalSourcesNumber (state, payload) {
      state.totalSourcesNumber = payload
    },
    setSourcesPage (state, payload) {
      state.sourcesPage = payload
    },
    setSourcesTotalPages (state, payload) {
      state.sourcesTotalPages = payload
    },
    setNewSource (state) {
      state.source = {
        extId: null,
        handler: null,
        imports: null,
        name: null,
        settings: {},
        state: 'active',
        timetable: null
      }
    },
    updateSource (state, payload) {
      state.source = {
        ...state.source,
        ...payload
      }
    },
    startSourcesLoading (state) {
      state.sourcesLoading = true
    },
    stopSourcesLoading (state) {
      state.sourcesLoading = false
    },
    setSourcesByIntegration (state) {
      const sourcesByIntegration = {};

      state.sources.forEach(item => {
        if (item._embedded && item._embedded.integration && item._embedded.integration.id) {
          const integrationId = item._embedded.integration.id;

          if (!sourcesByIntegration[integrationId]) {
            sourcesByIntegration[integrationId] = [];
          }
          sourcesByIntegration[integrationId].push(item);
        }
      });

      state.sourcesByIntegration = sourcesByIntegration;
    }
  },
  actions: {
    loadSources ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.sources)
      return services.iSource.getAll(query)
        .then(data => {
          commit('setSourcesData', data)
          return data
        })
    },
    loadOwnerSources ({ commit }, ownerId) {
      const query = mergeQuery({
        per_page: 200,
        page: 1,
        filter: [
          { type: 'eq', field: 'owner', value: ownerId }
        ]
      })

      return services.iSource.getAll(query)
        .then(data => {
          commit('setSourcesData', data)
          return data
        })
    },
    loadSource ({ commit }, payload) {
      return services.iSource.get(payload)
        .then(item => {
          commit('setSource', item)
          return item
        })
    },
    saveSource ({ commit, state }) {
      const data = state.source.id
        ? convertEmbedded(difference(state.source, state.cleanSource))
        : convertEmbedded(state.source)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.source)
      }

      const header = state.source.id && state.source.updated
        ? { 'X-Entity-Updated': state.source.updated }
        : {}

      return services.iSource.save(data, state.source.id, header)
    },
    saveSourceByPayload (_, payload) {
      return services.iSource.save(payload.data, payload.id, payload.header)
    }
  }
}
