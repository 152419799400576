const Shipments = () => import('../../pages/delivery-manager/DMParcels')
const Shipment = () => import('../../pages/delivery-manager/DMRequest.vue')

export default [
  {
    entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
    path: '/shipments',
    name: 'Shipments',
    icon: 'local_shipping',
    noChild: true,
    component: Shipments,
    options: {
      query: {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ]
      }
    },
    children: [
      {
        entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
        path: '/entity/:id',
        component: Shipment
      },
      {
        entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
        path: '/entity',
        component: Shipment
      }
    ]
  }
]
