import qs from 'qs'
import defaultRoutes from './config/PageRoutes'
import simpleRoutes from './config/SimplePageRoutes'
import { createRouter, createWebHashHistory } from 'vue-router'

let routes = defaultRoutes;
if (window.appOptions?.versionType == 'simple') {
  routes = simpleRoutes;
}

export const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    let result = qs.stringify(query)

    return result ? (result) : ''
  }
})
