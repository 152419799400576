export class ProfileService {
  _sender
  _address
  _client

  constructor (sender, address, client) {
    this._sender = sender
    this._address = address
    this._client = client
  }

  get (id, data) {
    let sender = null
    let clientProfile = null

    return Promise.resolve(!!data && data.id === id)
      .then(hasSender => {
        return hasSender
          ? data
          : this._sender.get(id)
      })
      .then(item => {
        sender = item

        if (sender._embedded.defaultSenderProfile) {
          return { ...sender._embedded.defaultSenderProfile }
        }

        const query = {
          filter: [{ type: 'eq', field: 'extId', value: `sender-${sender.id}` }]
        }

        return this._client.getAll(query).then(({ items }) => items[0])
      })
      .then(item => {
        clientProfile = item

        if (clientProfile) {
          const query = {
            filter: [{ type: 'eq', field: 'profile', value: clientProfile.id }]
          }

          return this._address.getAll(query)
            .then(({ items }) => items[0])
            .then(address => {
              if (!address && sender._embedded.parent) {
                return {}
              }

              return address
            })
        }

        return null
      })
      .then(address => {
        return { sender, clientProfile, address }
      })
  }
}
