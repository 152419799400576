const Orders = () => import('../../pages/wms/Orders')
const Order = () => import('../../pages/wms/Order')

export default [
  {
    entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
    path: '/orders',
    name: 'Orders',
    icon: 'qr_code',
    noChild: true,
    component: Orders,
    options: {
      query: {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ]
      }
    },
    children: [
      {
        entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
        path: '/entity/:type',
        component: Order
      },
      {
        entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
        path: '/entity/:type/:id',
        component: Order
      }
    ]
  }
]
