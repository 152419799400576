import _ from 'lodash'
import services from '../../../services/services'

import {
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    orders: [],
    lockedOrders: [],
    ordersItemsNumber: 0,
    ordersTotalPages: 1,
    ordersPage: 1,
    ordersLoading: false,
    order: null,
    cleanOrder: null,
    orderProducts: [],
    cleanOrderProducts: [],
    orderProductsLoading: false,
    hasOrderChange: false,
  },
  getters: {
    hasOrderChange: state => state.hasOrderChange,
    orders: state => state.orders,
    lockedOrders: state => state.lockedOrders,
    isOrderLocked: state => {
      if (!state.order || !state.order.id) {
        return false
      }

      return state.lockedOrders.includes(state.order.id)
    },
    ordersLoading: state => state.ordersLoading,
    ordersItemsNumber: state => state.ordersItemsNumber,
    ordersTotalPages: state => state.ordersTotalPages,
    ordersPage: state => state.ordersPage,
    order: state => state.order,
    cleanOrder: state => state.cleanOrder,
    orderProducts: state => state.orderProducts,
    cleanOrderProducts: state => state.cleanOrderProducts,
    orderProductsLoading: state => state.orderProductsLoading,
  },
  mutations: {
    setOrdersData (state, { items, page, totalPages, totalItems }) {
      state.orders = items
      state.ordersItemsNumber = totalItems
      state.ordersTotalPages = totalPages
      state.ordersPage = page
    },
    setOrders (state, payload) {
      state.orders = payload
    },
    setOrdersItemsNumber (state, payload) {
      state.ordersItemsNumber = payload
    },
    setOrdersTotalPages (state, payload) {
      state.ordersTotalPages = payload
    },
    setOrdersPage (state, payload) {
      state.ordersPage = payload
    },
    setOrder (state, payload) {
      state.order = payload
      state.cleanOrder = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanOrder (state, payload) {
      state.cleanOrder = payload ? _.cloneDeep(payload) : payload
    },
    updateCleanOrder (state, payload) {
      state.order = {
        ...state.cleanOrder,
        ...payload
      }
    },
    updateOrder (state, payload) {
      state.order = {
        ...state.order,
        ...payload
      }

      if (!payload.state || (payload.state && payload.length() > 1)) {
        state.hasOrderChange = true
      }
    },
    updateOrderEmbedded (state, payload) {
      state.order = {
        ...state.order,
        _embedded: {
          ...state.order._embedded,
          ...payload
        }
      }

      state.hasOrderChange = true
    },
    updateOrderSource (state, payload) {
      state.order = {
        ...state.order,
        _embedded: {
          ...state.order._embedded,
          source: payload
        }
      }

      state.hasOrderChange = true
    },
    setHasOrderChange (state, payload) {
      state.hasOrderChange = payload
    },
    addOrderToList (state, payload) {
      state.orders = [payload, ...state.orders]
    },
    setOrderFromList (state, payload) {
      state.orders = state.orders.map(order => {
        return Number(order.id) === Number(payload.id)
          ? payload
          : order
      })
    },
    setOrderProducts (state, payload) {
      state.orderProducts = payload
    },
    setCleanOrderProducts (state, payload) {
      state.cleanOrderProducts = _.cloneDeep(payload)
    },
    setNewOrder (state, payload) {
      const defaultState = {
        ...(state.appOptions.defaults.order || {}),
        state: 'pending_queued',
        type: 'retail',
        extId: null,
        date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        orderPrice: 0,
        totalPrice: 0,
        eav: {},
        _embedded: {
          shop: undefined,
          profile: {},
          phone: {}
        }
      }

      if (payload && payload.type && window.appOptions?.orderTypes[payload.type]?.options) {
        state.order = {
          ...defaultState,
          ...window.appOptions.orderTypes[payload.type].options
        }
      } else {
        state.order = { ...defaultState }
      }
    },
    startProductLoading (state) {
      state.orderProductsLoading = true
    },
    stopProductLoading (state) {
      state.orderProductsLoading = false
    },
    startOrderLoading (state) {
      state.ordersLoading = true
    },
    stopOrderLoading (state) {
      state.ordersLoading = false
    },
    addLockedOrder (state, payload) {
      if (state.lockedOrders.find(id => id === payload)) {
        return
      }

      state.lockedOrders = [...state.lockedOrders, payload]
    },
    removeLockedOrder (state, payload) {
      state.lockedOrders = state.lockedOrders.filter(id => id !== payload)
    },
    upsertOrder (state, payload) {
      let isExist = false

      state.orders = state.orders.map(x => {
        if (x.id == payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.orders.unshift(payload)
      }
    }
  },
  actions: {
    resetOrder ({ commit }) {
      commit('setOrder', null)
      commit('setDeliveryRequest', null)
      commit('setAddress', null)
      commit('setOrderProducts', [])
    },
    loadOrders ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.orders)
      return services.order.getAll(query)
        .then(data => {
          commit('setOrdersData', data)
          return data
        })
    },
    loadOrderProducts ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.orderProducts)

      return services.order.getProducts(query)
        .then(({ items, totalItems, page, totalPages }) => {
          commit('setOrderProducts', items)
          commit('setCleanOrderProducts', items)

          return { items, totalItems, page, totalPages }
        })
    }
  }
}
