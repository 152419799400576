import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class FileService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/file', httpClient, middleware)
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<excel>}
   */
   getAll (query) {
    return this._httpClient.get(this._url, query)
  }
}

export const fileService = new FileService(HALApiService, createItemsData)
