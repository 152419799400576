import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    deliveryService: null,
    cleanDeliveryService: null,
    deliveryServices: [],
    deliveryServicesItemsNumber: 0,
    deliveryServicesPage: 1,
    deliveryServicesTotalPages: 1,
    deliveryServicesLoading: false
  },
  getters: {
    deliveryService: state => state.deliveryService,
    cleanDeliveryService: state => state.cleanDeliveryService,
    deliveryServices: state => state.deliveryServices,
    deliveryServicesLoading: state => state.deliveryServicesLoading,
    deliveryServicesItemsNumber: state => state.deliveryServicesItemsNumber,
    deliveryServicesPage: state => state.deliveryServicesPage,
    deliveryServicesTotalPages: state => state.deliveryServicesTotalPages
  },
  mutations: {
    setDeliveryServicesData (state, { items, page, totalPages, totalItems }) {
      state.deliveryServices = items
      state.deliveryServicesItemsNumber = totalItems
      state.deliveryServicesPage = page
      state.deliveryServicesTotalPages = totalPages
    },
    setDeliveryServices (state, payload) {
      state.deliveryServices = payload
    },
    setDeliveryServicesItemsNumber (state, payload) {
      state.deliveryServicesItemsNumber = payload
    },
    setDeliveryServicesPage (state, payload) {
      state.deliveryServicesPage = payload
    },
    setDeliveryServicesTotalPages (state, payload) {
      state.deliveryServicesTotalPages = payload
    },
    setDeliveryService (state, payload) {
      state.deliveryService = payload

      state.cleanDeliveryService = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanDeliveryService (state, payload) {
      state.cleanDeliveryService = payload
    },
    setNewDeliveryService (state) {
      state.deliveryService = {}
    },
    updateDeliveryService (state, payload) {
      state.deliveryService = {
        ...state.deliveryService,
        ...payload
      }
    },
    startDeliveryServicesLoading (state) {
      state.deliveryServicesLoading = true
    },
    stopDeliveryServicesLoading (state) {
      state.deliveryServicesLoading = false
    },
    setSelectedCourier (state, payload) {
      state.selectedCourier = payload
    }
  },
  actions: {
    loadDeliveryServices ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.deliveryServices)
      return services.deliveryService.getAll(query)
        .then(data => {
          commit('setDeliveryServicesData', data)
          return data
        })
    },
    loadDeliveryService ({ commit }, payload) {
      return services.deliveryService.get(payload)
        .then(item => {
          commit('setDeliveryService', item)
          return item
        })
    },
    saveDeliveryService ({ commit, state }) {
      const data = state.deliveryService.id
        ? convertEmbedded(difference(state.deliveryService, state.cleanDeliveryService))
        : convertEmbedded(state.deliveryService)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.deliveryService)
      }

      const header = state.deliveryService.id && state.deliveryService.updated
        ? { 'X-Entity-Updated': state.deliveryService.updated }
        : {}

      return services.deliveryService.save(data, state.deliveryService.id, header)
    }
  }
}
