import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import './../../assets/css/style.css'

import services from './services'
import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import utils from './utils'
import dynamicComponents from './dynamic-components'
import moment from 'moment-timezone'
import i18n from './../../i18n'
import Quasar from 'quasar/src/vue-plugin.js';import AppFullscreen from 'quasar/src/plugins/app-fullscreen/AppFullscreen.js';
import WebSocket, { centrifuge } from './plugins/websocket'
import { authHeader } from './services/hal-api.service'
import SocketHelper from './plugins/socket'
import { channelAdapters, channels } from './socket-helpers/socket-helpers'
import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import FormBuilder from './../../packages/form-builder'
import DynamicCounter from './components/objects/dynamic-counter.vue'
import DynamicCounterModal from './components/objects/dynamic-counter-modal.vue'
import CounterModal from './components/modals/CounterModal.vue'
import Portal from '../../packages/portal'
import store from './store'

export function bootstrap (root, eventBus, options) {
  const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
  })

  const app = createApp(App)

  app.config.globalProperties.$eventBus = eventBus
  app.config.globalProperties.$appOptions = options.appOptions
  app.config.globalProperties.$defaultAppOptions = options.defaultAppOptions
  app.config.globalProperties.$service = services
  app.config.globalProperties.$utils = utils
  app.config.globalProperties.$dynamicComponents = dynamicComponents
  app.config.globalProperties.$entities = options.entities
  app.config.globalProperties.$moment = moment

  app.use(store)
  app.use(i18n)
  app.use(router)
  app.use(Quasar, {
    animations: 'all',
    plugins: {
      AppFullscreen
    }
  })
  app.use(WebSocket, {
    service: 'wss://centrifugo.orderadmin.eu/connection/websocket',
    options: () => {
      return {
        debug: true,
        subscribeEndpoint: `${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/subscribe`,
        subscribeHeaders: authHeader(),
        subscribeParams: {}
      }
    }
  })
  app.use(SocketHelper, {
    socket: centrifuge,
    channels,
    store,
    adapters: channelAdapters
  })
  app.use(FormBuilder)
  app.use(Portal)

  app.component('dynamic-counter', DynamicCounter)
  app.component('dynamic-counter-modal', DynamicCounterModal)
  app.component('counter-modal', CounterModal)
  app.component('error-screen', defineAsyncComponent(() => import('./components/modals/ErrorScreen.vue')))
  app.component('confirm-modal', defineAsyncComponent(() => import('./components/modals/ConfirmModal.vue')))
  app.component('notifications', defineAsyncComponent(() => import('./components/notifications/Notifications.vue')))
  app.component('terminal-header', defineAsyncComponent(() => import('./components/terminal-header/terminal-header.vue')))
  app.component('place-object', defineAsyncComponent(() => import('./components/objects/place-object.vue')))
  app.component('order-item', defineAsyncComponent(() => import('./components/objects/order-item.vue')))
  app.component('order-object', defineAsyncComponent(() => import('./components/objects/order-object.vue')))
  app.component('assistant-object', defineAsyncComponent(() => import('./components/objects/assistant-object.vue')))
  app.component('terminal-menu', defineAsyncComponent(() => import('./components/menu/Menu.vue')))
  app.component('loader', defineAsyncComponent(() => import('./components/loader/Loader.vue')))
  app.component('tiles', defineAsyncComponent(() => import('./components/menu/Tiles.vue')))
  app.component('terminal-title', defineAsyncComponent(() => import('./components/terminal-header/title.vue')))
  app.component('picking-item', defineAsyncComponent(() => import('./components/objects/picking-item.vue')))
  app.component('picking-message', defineAsyncComponent(() => import('./components/objects/picking-message.vue')))
  app.component('picking-queue', defineAsyncComponent(() => import('./components/objects/picking-queue.vue')))
  app.component('picking-order', defineAsyncComponent(() => import('./components/objects/picking-order.vue')))
  app.component('picking-offer', defineAsyncComponent(() => import('./components/objects/picking-offer.vue')))
  app.component('picking-task', defineAsyncComponent(() => import('./components/objects/picking-task.vue')))
  app.component('product-object', defineAsyncComponent(() => import('./components/objects/product-object.vue')))
  app.component('batch-object', defineAsyncComponent(() => import('./components/objects/batch-object.vue')))
  app.component('acceptance-object', defineAsyncComponent(() => import('./components/objects/acceptance-object.vue')))
  app.component('dynamic-collection', defineAsyncComponent(() => import('./components/objects/dynamic-collection.vue')))
  app.component('place-container', defineAsyncComponent(() => import('./components/tables/PlaceContainer.vue')))
  app.component('inventory-object', defineAsyncComponent(() => import('./components/objects/inventory-object.vue')))
  app.component('offers-search', defineAsyncComponent(() => import('./components/objects/offers-search.vue')))

  // Global
  app.component('q-token', defineAsyncComponent(() => import('./components/global/QToken.vue')))

  // Modals
  app.component('offers-modal', defineAsyncComponent(() => import('./components/modals/OffersModal.vue')))
  app.component('acceptance-items-modal', defineAsyncComponent(() => import('./components/modals/AcceptanceItemsModal.vue')))
  app.component('offers-search-modal', defineAsyncComponent(() => import('./components/modals/OffersSearchModal.vue')))
  app.component('offer-modal', defineAsyncComponent(() => import('./components/modals/OfferModal.vue')))
  app.component('dimensions-modal', defineAsyncComponent(() => import('./components/modals/DimensionsModal.vue')))
  app.component('request-data-modal', defineAsyncComponent(() => import('./components/modals/RequestDataModal.vue')))
  app.component('question-modal', defineAsyncComponent(() => import('./components/modals/QuestionModal.vue')))
  app.component('location-logs', defineAsyncComponent(()=>import('./components/modals/LocationLogsModal.vue')))

  // Panels
  app.component('jumbotron', defineAsyncComponent(() => import('./components/panels/Jumbotron.vue')))

  // Sections
  app.component('place-section-object', defineAsyncComponent(() => import('./components/sections/PlaceSectionObject.vue')))
  app.component('place-section', defineAsyncComponent(() => import('./components/sections/PlaceSection.vue')))
  app.component('base-place-section', defineAsyncComponent(() => import('./components/sections/BasePlaceSection.vue')))
  app.component('product-section', defineAsyncComponent(() => import('./components/sections/ProductSection.vue')))
  app.component('item-section', defineAsyncComponent(() => import('./components/sections/ItemSection.vue')))
  app.component('quantity-section', defineAsyncComponent(() => import('./components/sections/QuantitySection.vue')))
  app.component('counter-section', defineAsyncComponent(() => import('./components/sections/CounterSection.vue')))

  return app
}
