export default [
  {
    entityClass: 'Picking',
    path: '/terminal',
    name: 'Terminal',
    icon: 'qr_code',
    options: {
      styleClasses: window.innerWidth < 700
          ? 'd-none'
          : ''
    },
    ignoreRedirect: true,
    redirect: '/'
  }
]
