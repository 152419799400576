export class FiedUtil {
  _serviceFactory
  _lodash

  constructor (factory, lodash) {
    this._serviceFactory = factory
    this._lodash = lodash

    this.createTextInput = this.createTextInput.bind(this)
    this.createPasswordInput = this.createPasswordInput.bind(this)
    this.createNumberInput = this.createNumberInput.bind(this)
    this.createCheckbox = this.createCheckbox.bind(this)
    this.createSelectField = this.createSelectField.bind(this)
    this.createDateField = this.createDateField.bind(this)
    this.createQSelectField = this.createQSelectField.bind(this)
    this.createTagField = this.createTagField.bind(this)
    this.createTableInput = this.createTableInput.bind(this)
  }

  /**
   * @param {object} data
   *
   * @returns {string}
   */
  _getLabel (data) {
    if (data.label) {
      return data.label
    }

    if (data.options && data.options.label) {
      return data.options.label
    }

    return ''
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createTextInput (data) {
    return {
      type: 'input',
      inputType: data.inputType || 'text',
      field: data.name,
      label: this._getLabel(data),
      placeholder: data.placeholder,
      required: !!data.required,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createPasswordInput (data) {
    return this.createTextInput({ ...data, inputType: 'password' })
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createNumberInput (data) {
    return this.createTextInput({ ...data, inputType: 'number' })
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createCheckbox (data) {
    return {
      type: 'switch',
      model: data.name,
      label: this._getLabel(data),
      required: !!data.required,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createSelectField (data) {
    const field = {
      type: 'multiselect',
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs',
      field: data.name,
      label: this._getLabel(data),
      required: !!data.require,
      customLabel (row) {
        if (row && typeof row === 'object' && data.options?.api?.format?.js) {
          const template = data.options.api.format.js
          const interpolated = template.replace(/\{\{(.*?)\}\}/g, (_, match) => row[match.trim()])
          return interpolated
        } else if (row && typeof row === 'object') {
          return `${row.name} (${row.id})`
        }

        return JSON.stringify(row)
      },
      onScroll: (search, page) => {
        const query = {
          per_page: 25,
          page,
          search
        }
        return this._serviceFactory(data.api.isArray || typeof data.api === 'object' ? data.api.url : data.api).getAll(query)
      }
    }

    return field
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createDateField (data) {
    return {
      type: 'date',
      field: data.name,
      label: this._getLabel(data),
      placeholder: data.placeholder,
      required: !!data.require,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {any} options
   *
   * @returns {array}
   */
  _getOptions (options) {
    if (Array.isArray(options)) {
      return options
    }

    if (options && typeof options === 'object') {
      if (options.values) {
        return options.values
      }

      let acc = []
      for (const [key, value] of Object.entries(options)) {
        if (key) {
          acc.push({ id: key, name: value })
        }
      }

      return acc
    }

    return []
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createQSelectField (data = {}) {
    return {
      type: 'select',
      label: this._getLabel(data),
      field: data.name,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs',
      options: this._getOptions(data.values || data.options?.value_options),
      required: !!data.require,
      customLabel (row) {
        return row && typeof row === 'object'
          ? row.name || row.title
          : row
      }
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createTableInput (data = {}) {
    return {
      type: 'table',
      inputType: data.inputType || 'text',
      field: data.name,
      label: this._getLabel(data),
      placeholder: data.placeholder,
      required: !!data.required,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createTagField (data = {}) {
    const field = {
      type: 'tag',
      label: this._getLabel(data),
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs',
      field: data.name,
      options: this._getOptions(data.values || data.options),
      customListItem: (val) => {
        if (val && typeof val === 'object') {
          return `${val.name || val.title} (${val.id})`
        }

        return val
      }
    }

    if (data.api && data.api.url) {
      field.onScroll = (search, page) => {
        const query = {
          per_page: 25,
          page,
          search,
          filter: []
        }

        return this._serviceFactory(data.api.url).getAll(query)
      }
    }

    return field
  }

  /**
   * @param {object} model
   * @param {object} data
   *
   * @returns {object}
   */
  create (model, data) {
    const types = {
      'Laminas\\Form\\Element\\Text': this.createTextInput,
      'Laminas\\Form\\Element\\Textarea': this.createTextInput,
      'Laminas\\Form\\Element\\Password': this.createPasswordInput,
      'Laminas\\Form\\Element\\Number': this.createNumberInput,
      'Laminas\\Form\\Element\\Url': this.createTextInput,
      'DoctrineModule\\Form\\Element\\ObjectSelect': this.createTextInput,
      'Zend\\Form\\Element\\Url': this.createTextInput,
      'Laminas\\Form\\Element\\Checkbox': this.createCheckbox,
      'Application\\Form\\Element\\Select2': this.createSelectField,
      'Orderadmin\\Application\\Form\\Element\\Select2': this.createSelectField,
      'Orderadmin\\Application\\Form\\Element\\Multiselect': this.createTagField,
      'Laminas\\Form\\Element\\Select': this.createQSelectField,
      'Laminas\\Form\\Element\\Date': this.createDateField,
      'Laminas\\Form\\Element\\Tag': this.createTagField,
      'Orderadmin\\Application\\Form\\Element\\ParamsMatrix': this.createTableInput,
      'Orderadmin\\Application\\Form\\Element\\JsonEditor': this.createTextInput
    }

    if (!types[data.type]) {
      console.error(`Field is not recognized -> ${JSON.stringify(data)}`)
      return null
    }

    const field = data.type === 'Orderadmin\\Application\\Form\\Element\\Select2' && data.options && data.options.multiple
      ? types['Orderadmin\\Application\\Form\\Element\\Multiselect'](data)
      : types[data.type](data)

    field.value = this._lodash.get(model, data.name)

    if (field.type === 'switch') {
      field.value = !!field.value
    }

    if (field.type === 'tag') {
      field.onRemove = (val) => {
        const removeVal = val && typeof val === 'object'
          ? val.id
          : val

        const update = (this._lodash.get(model, data.name) || []).filter(x => x !== removeVal)
        this._lodash.updateWith(model, data.name, () => update)
        typeof field.watch === 'function' && field.watch(update)
      }

      field.onAdd = (value) => {
        if (Array.isArray(value)) {
          return
        }

        let isExist = false

        const updateValue = value && typeof value === 'object'
          ? value.id
          : value

        let update = (this._lodash.get(model, data.name) || []).filter(val => {
          if (val === updateValue) {
            isExist = true
            return false
          }

          return true
        })

        if (!isExist) {
          update = [...(this._lodash.get(model, data.name) || []), updateValue]
        }

        this._lodash.updateWith(model, data.name, () => update)
        typeof field.watch === 'function' && field.watch(update)
      }
    } else {
      field.onChange = (value) => {
        const val = value && typeof value === 'object' && value.id
          ? value.id
          : value

        this._lodash.updateWith(model, data.name, () => val)

        typeof field.watch === 'function' && field.watch(value)
        return value
      }
    }

    return field
  }
}
